.overlay {
  position: absolute;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.11) 0%,
    rgba(0, 0, 0, 0.856) 64.62%,
    rgba(0, 0, 0, 0.918) 84.92%
  );

  display: flex;
  align-items: center;
  justify-content: center;
}
.tag {
  span {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
  }
}

.carousel {
  position: relative;

  & > div:first-child {
    border-radius: 0;

    & img {
      width: 100%;
      z-index: -10;
      object-fit: cover;
      position: relative;
    }
  }
  .overlay {
    @extend .overlay;
  }
  div button.group {
    z-index: 10;
  }
}

.latest_articles {
  .overlay {
    @extend .overlay;
    align-items: flex-start;
    justify-content: flex-end;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }
}
